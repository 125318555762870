if (typeof window.Plastic === "undefined") var Plastic = {};

// Module Name Format: PlasticModule-1.0.1
// Resource: path|URL|moduleName
// From: "repo"

if (typeof Plastic.Load === "undefined"){
    Plastic.Load = async function(resource, options = {}){
        if (Array.isArray(resource)){
            var loads = [];
            for (var i = 0; i < resource.length; i++){
                loads.push(Plastic.Load(resource[i], options));
            }
            return await Promise.all(loads);
        }

        var {                       // OPTIONS:
            dependencies = null,    // things to load first
            exportName = null,      // variable to look for in the file, also doubles as new global name
            from = null,            // will default to load from repo
            initialize = false,     // should Load call exportName.init
            protect = false,        // Only applies to non-module JS
            domInsert = "append",   // Only applies to type HTML can be "append" || "prepend"
            reload = false,         // Currently only used by loadHTML allows for skipping the already loaded check
            waitForCSS = false,
        } = options;

        await loadDependencies();

        if (typeof resource !== "string"){
            // {exportName: "path"}
            exportName = Object.keys(resource)[0];
            resource = resource[exportName];

            // For this type if protect not given then default to true
            if (typeof options["protect"] === "undefined"){
                protect = true;
            }
        }

        function RequestObj(resource){
            this.resource = resource;
            this.type = getType();
            this.isModule = this.type === "module";
            this.ext = this.isModule ? "js" : this.type;

            if (this.isModule) {
                this.module = moduleInfo();
                this.url = getURL(this.module.path);
            }
            else {
                this.url = getURL(this.resource);
            }

            function getType(){
                var parts = resource.split(".");
                var extension = parts[parts.length - 1].toLowerCase();

                // Assumes if extension is numeric then it is a module.
                var isModule = parts.length === 1 || !isNaN(parseInt(extension));
                return isModule ? "module" : extension;
            }
            function getURL(resource){
                var isExternal = resource.indexOf("http") === 0;
                var isLocal = resource.indexOf("/") === 0;
                var fromRepo = from !== null && from.toLowerCase() === "repo";
                fromRepo = fromRepo || (!from && !isExternal && !isLocal);
                var repoURL = Plastic.Load.repo;

                resource = appendLoadParams(resource);

                if (fromRepo)           return `${repoURL}${resource}`; // Load from Repo
                else if (from !== null) return `${from}${resource}`;    // Path or URL was given
                else                    return resource;                // isLocal or isExternal
            }
            function moduleInfo(){
                var request = resource.split("-");
                var name = request[0];
                var path = `${name}.min.js`;
                var version = request[1];

                var hasVersion = typeof version !== "undefined";
                if (hasVersion) path = `${version}/${path}`;

                return {name: name, path: path, version: version}
            }
        }
        var request = new RequestObj(resource);

        // Check if currently loading resource and wait
        await waitIfLoading(request.url);

        // Mark as loading (so other will wait)
        loading(request.url);

        // Is the resource already loaded?
        if (isLoaded(request.url) && !reload) {
            doneLoading();
            return;
        }

        // Load resource
        try {
            // skipLoaded used for concurrent loads
            var skipLoaded = await load(request);
        }
        catch(e){
            console.error(`Failed to load: ${request.url}`, e);
        }

        // Wrap up
        doneLoading();

        async function loadDependencies(){
            // This is not related to Plastic modules
            if (dependencies === null) return;
            if (!Array.isArray(dependencies)) dependencies = [dependencies];

            var loads = [];
            for (var i = 0; i < dependencies.length; i++){
                loads.push(Plastic.Load(dependencies[i]));
            }
            return await Promise.all(loads);
        }
        async function load(request){
            if      (request.type === "css")    return await loadCSS();
            else if (request.type === "html")   return await loadHTML();
            else if (request.type === "module") return await loadModule();
            else if (request.type === "js")     return await (protect ? ajaxJS() : loadJS());

            async function ajaxJS(){
                await requestModuleReady();
                var functionBody = await Plastic.Request(request.url);

                // Fix sourceMap references
                functionBody = ajaxSourceMaps(functionBody, request.url);

                if (isLoaded(request.url)) return; // Loaded elsewhere.

                if (exportName !== null){
                    functionBody = `var module = {}; ${functionBody} \n; \n`;
                    functionBody += `return typeof module.exports === "undefined" ? ${exportName} : module.exports;`;
                    window[exportName] = (new Function(functionBody))();
                    var hasInit = typeof window[exportName]["init"] === "function";
                    if (initialize && hasInit) window[exportName]["init"]();
                }
                else (new Function(functionBody))(); // Executes Immediately
            }
            async function loadHTML(){
                await requestModuleReady();
                var HTML = await Plastic.Request(request.url);

                if (isLoaded(request.url) && !reload) return; // Already loaded
                if (exportName !== null){
                    window[exportName] = HTML;
                }
                else {
                    var body = document.body;
                    HTML = document.createRange().createContextualFragment(HTML);
                    if (domInsert === "prepend"){
                        body.prepend(HTML);
                    }
                    else { // Default to append
                        body.append(HTML);
                    }
                }
            }
            function loadCSS(){
                return new Promise(resolve => {
                    var link    = document.createElement("link");
                    if (waitForCSS) link.onload = resolve;

                    link.href   = request.url;
                    link.type   = "text/css";
                    link.rel    = "stylesheet";
                    document.head.appendChild(link);

                    if (!waitForCSS) resolve();
                });
            }
            function loadJS(){
                return new Promise(resolve => {
                    var script      = document.createElement('script');
                    script.src      = request.url;
                    script.onload   = resolve;
                    if (document.body === null) document.head.appendChild(script); // Pre-body load
                    else document.body.appendChild(script);
                });
            }
            async function loadModule(){
                var module = request.module;
                if (typeof Plastic[module.name] !== "undefined") return true;

                await requestModuleReady();
                var response = await Plastic.Request(request.url);

                if (isLoaded(request.url)) return true; // Loaded elsewhere.

                var funcBody = `var module = {}; ${response} \n; \n`;
                funcBody += `return typeof module.exports === "undefined" ? ${module.name} : module.exports;`;
                funcBody = ajaxSourceMaps(funcBody, request.url);
                Plastic[module.name] = (new Function(funcBody))();

                module = Plastic[request.module.name];
                var dependencies = module["dependencies"];
                var depends = typeof dependencies !== "undefined";

                if (depends) await Plastic.Load(dependencies);

                if (isLoaded(request.url)) return true; // Loaded elsewhere.

                // Initialize Module
                if (typeof module.init !== "undefined"){
                    await new Promise(resolve => {
                        var wait = module.init(resolve); // Return true if using callback
                        if (typeof wait === "undefined" || !wait) resolve();
                        else if (typeof wait.then === "function"){
                            // Probably returned promise
                            wait.then(resolve);
                        }
                    });
                }
            }
        }
        function appendLoadParams(resource){
            var isExternal = resource.indexOf("http") === 0;
            var hasLoadParams = typeof PLASTIC_LOAD_PARAMS !== "undefined";
            if (hasLoadParams && !isExternal){
                var loadParams = Object.keys(PLASTIC_LOAD_PARAMS);
                for (var i = 0; i < loadParams.length; i++){
                    let loadParamName = loadParams[i];
                    let loadParamValue = PLASTIC_LOAD_PARAMS[loadParamName];

                    let hasQuery = resource.indexOf("?") > 0;
                    let separator = hasQuery ? "&" : "?";
                    resource += `${separator}${loadParamName}=${loadParamValue}`;
                }
            }
            return resource;
        }
        function ajaxSourceMaps(body, url){
            url = url.split("/").slice(0, -1).join("/");
            var mapStr = "sourceMappingURL=";
            return body.replace(mapStr, `${mapStr}${url}/`);
        }
        function loading(url){
            Plastic.Load.Loading.push(url);
        }
        function doneLoading(){
            // Remove from loading
            var loadingIndex = Plastic.Load.Loading.indexOf(request.url);
            Plastic.Load.Loading.splice(loadingIndex, 1);

            // Mark as loaded
            if (!isLoaded(request.url) && !skipLoaded) Plastic.Load.Loaded.push(request.url);
        }
        function isLoaded(url){
            return Plastic.Load.Loaded.indexOf(url) >= 0;
        }
        function isLoading(url){
            return Plastic.Load.Loading.indexOf(url) >= 0;
        }
        function requestModuleReady(){
            return new Promise(resolve => {
                var interval = setInterval(() => {
                    if (isReady()){
                        clearInterval(interval);
                        return resolve();
                    }
                }, 5);
            });

            function isReady(){
                return typeof Plastic.Request !== "undefined";
            }
        }
        function waitIfLoading(url){
            return new Promise(resolve => {
                var interval = setInterval(() => {
                    if (!isLoading(url)){
                        clearInterval(interval);
                        return resolve();
                    }
                }, 5);
            });
        }

        Plastic.Load.return = async function(resource, type, exportVar){
            resource = appendLoadParams(resource);

            await requestModuleReady();

            try {
                var raw = await Plastic.Request(resource);
            }
            catch (e){
                console.warn(`Resource not found: ${resource}`);
                return;
            }
            // Fix sourceMap references
            if (isString(raw)){ // JSON is parsed by Request
                raw = ajaxSourceMaps(raw, resource);
            }

            switch(type.toLowerCase()){
                case "html":
                    raw = document.createRange().createContextualFragment(raw);
                    break;
                case "js":
                    raw = `var module = {}; ${raw} \n; \n`;
                    raw += `return typeof module.exports === "undefined" ? ${exportVar} : module.exports;`;
                    raw = (new Function(raw))();
                    break;
                case "json":
                    if (isString(raw)) raw = JSON.parse(raw);
            }
            return raw;
        }
    };
    Plastic.Load.dependencies = ["Request.min.js"];
    Plastic.Load.Loaded = [];
    Plastic.Load.Loading = [];
    Plastic.Load.ready = false;
    Plastic.Load.repo = "https://load.aplastic.app/content/";

    var loadPath = document.documentElement.querySelector("script[plastic-load-path]");
    if (loadPath){
        loadPath = loadPath.getAttribute("plastic-load-path");
        if (loadPath) Plastic.Load.repo = loadPath;
    }

    Plastic.Load.init = async function(){
        if (typeof $ === "undefined") Plastic.Load.dependencies.push("https://code.jquery.com/jquery-1.12.4.min.js");
        await Plastic.Load(Plastic.Load.dependencies);
        var loadScript = document.documentElement.querySelector("script[plastic-load]");
        if (loadScript){
            var modules = loadScript.getAttribute("plastic-load").split(",");
            for (var i = 0; i < modules.length; i++){
                await Plastic.Load(modules[i].trim());
            }
        }
    };

    Plastic.Load.init();
}